<template>
  <div
    itemscope
    itemtype="http://schema.org/Product"
    class="hidden"
  >
    <span itemprop="name">
      {{ product.name | htmlDecode }}
    </span>
    <span itemprop="description">
      {{ product.description | htmlDecode }}
    </span>
    <meta itemprop="gtin13" :content="product.gtin">
    <span itemprop="sku" :content="product.sku">
      {{ $t("SKU") }}: {{ product.parentSku }}
    </span>
    <meta itemprop="brand" :content="getLabelforValue('manufacturer', product.manufacturer)">
    <meta
      v-if="gallery && gallery.length"
      itemprop="image"
      :content="gallery[0].src"
    >
    <div
      v-if="reviewArr && reviewArr.length > 0"
      itemprop="review"
      itemscope
      itemtype="http://schema.org/Review"
      class="hidden"
    >
      <div
        v-for="(review, index) in reviewArr"
        :key="index"
      >
        <meta itemprop="name" :content="review.title">
        <meta itemprop="author" :content="review.nickname">
        <meta itemprop="datePublished" :content="review.created_at">
        <meta itemprop="description" :content="review.detail">
      </div>
    </div>
    <div v-if="aggregateRating && reviewCount" itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating" class="hidden">
      <span v-if="aggregateRating" itemprop="ratingValue">{{ aggregateRating }}</span>
      <span v-if="reviewCount" itemprop="reviewCount">{{ reviewCount }}</span>
    </div>
    <template v-if="product.gtin">
      <meta itemprop="gtin" :content="product.gtin">
    </template>
    <template v-else>
      <meta itemprop="mpn" :content="product.mpn">
    </template>
    <div itemprop="offers" itemscope itemtype="https://schema.org/AggregateOffer">
      <meta itemprop="priceCurrency" content="PLN">
      <span v-if="minPrice" itemprop="lowPrice">{{ minPrice.price_incl_tax }}</span>
      <span v-if="minPrice" itemprop="highPrice">{{ maxPrice.price_incl_tax }}</span>
      <span itemprop="offerCount">{{ activeLength }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from 'config'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'

export default {
  name: 'ProductMeta',
  props: {
    product: {
      type: Object,
      required: true,
      default: null
    },
    gallery: {
      type: Array,
      required: true,
      default: null
    },
    aggregateRating: {
      type: Number,
      required: false,
      default: null
    },
    reviewCount: {
      type: Number,
      required: false,
      default: null
    },
    reviewArr: {
      type: Array,
      required: false,
      default: () => []
    },
    minPrice: {
      type: Object,
      required: false,
      default: null
    },
    maxPrice: {
      type: Object,
      required: false,
      default: null
    },
    activeLength: {
      type: Number,
      required: true
    }
  },
  mixins: [getLabelMixin],
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/attributeListByCode'
    }),
    hostName () {
      return config.server.hostName
    }
  }
};
</script>

<style lang="scss" scoped></style>
