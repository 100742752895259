<template>
  <div class="col-xs-12 relative">
    <div class="flex between-xs middle-xs cl-alternative my10 relative">
      <span class="flex between-xs middle-xs"><i class="icon-buy pr5 icon-size" />{{ $t('Odbiór w sklepie') }}</span>
      <span v-show="!isMobile" @mouseenter="overTip(1)" @mouseleave="leaveTip()" class="pointer flex middle-xs">
        <i class="icon-info icon-size" />
      </span>
      <transition name="fade">
        <div v-if="selectedTip && selectedTipText === 1" class="p10 bg-cl-alternative cl-white h6 absolute tip-position">
          Odbiór w sklepie - Złóż zamówienie i odbierz je za darmo w naszym sklepie
        </div>
      </transition>
      <span v-show="isMobile" @click="showDeliveryInfoModal(1)" class="pointer relative flex middle-xs">
        <i class="icon-info icon-size" />
      </span>
    </div>
    <div class="flex between-xs middle-xs cl-alternative my10">
      <span class="flex between-xs middle-xs"><i class="icon-ag_dostawa fs-medium pr5" />Darmowy transport od 5000zł</span>
      <span v-show="!isMobile" @mouseenter="overTip(2)" @mouseleave="leaveTip()" class="pointer flex middle-xs">
        <i class="icon-info icon-size" />
      </span>
      <transition name="fade">
        <div v-if="selectedTip && selectedTipText === 2" class="p10 bg-cl-alternative cl-white h6 absolute tip-position">
          Darmowa dostawa - na terenie Polski
        </div>
      </transition>
      <span v-show="isMobile" @click="showDeliveryInfoModal(2)" class="pointer relative flex middle-xs">
        <i class="icon-info icon-size" />
      </span>
    </div>
    <div class="flex between-xs middle-xs cl-forest-green my10" v-if="currentChildQty">
      <span class="flex between-xs middle-xs"><i class="icon-history icon-size pr5 cl-forest-green" />{{ $t('Wysyłamy w 24h!') }}</span>
      <span v-show="!isMobile" @mouseenter="overTip(3)" @mouseleave="leaveTip()" class="pointer flex middle-xs">
        <i class="icon-info icon-size" />
      </span>
      <transition name="fade">
        <div v-if="selectedTip && selectedTipText === 3" class="p10 bg-cl-alternative cl-white h6 absolute tip-position">
          Wysyłki realizowane są w dni robocze od poniedziałku do piątku. Czas dostawy zależy od przewoźnika, rodzaju przesyłki oraz adresu odbiorcy.
          Zazwyczaj zajmuje to do 2 dni roboczych.
        </div>
      </transition>
      <span v-show="isMobile" @click="showDeliveryInfoModal(3)" class="pointer relative flex middle-xs">
        <i class="icon-info icon-size" />
      </span>
    </div>
    <delivery-info-modal :selected="selectedTipText" v-if="loadDiliveryModal" />
  </div>
</template>

<script>
import { MobileDetected } from 'theme/components/core/mobileDetected.ts'
import isEcommerceMixin from 'theme/mixins/isEcommerceMixin.ts'

const DeliveryInfoModal = () => import(/* webpackChunkName: "vsf-languages-modal" */ 'theme/components/core/blocks/Product/DeliveryInfoModal.vue')
export default {
  name: 'DeliveryInfo',
  components: {
    DeliveryInfoModal
  },
  mixins: [MobileDetected, isEcommerceMixin],
  props: {
    getCurrentProduct: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedTip: false,
      selectedTipText: 1,
      loadDiliveryModal: false
    }
  },
  methods: {
    showDeliveryInfoModal (index) {
      this.selectedTipText = index
      this.loadDiliveryModal = true
      this.$bus.$emit('modal-show', 'modal-diliveryinfo')
    },
    overTip (index) {
      this.selectedTip = true
      if (index === 1) {
        this.selectedTipText = 1
      }
      if (index === 2) {
        this.selectedTipText = 2
      }
      if (index === 3) {
        this.selectedTipText = 3
      }
    },
    leaveTip () {
      this.selectedTip = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-alternative: color(alternative);
.icon-size {
  font-size: 21px;
}
.tip-position {
  right: 38px;
  z-index: 1;
}
.tip-position::after{
  content: '';
  width: 0;
  height: 0;
  right: -10px;
  top: calc(50% - 15px);
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid $color-alternative;
  position: absolute;
  clear: both;
}
</style>
