<template>
  <div class="col-xs-12 bg-cl-white shadow-hover-elemet">
    <no-ssr>
      <div class="row m0 my20 w-100">
        <div class="col-xs-4 p0 center-xs flex column-xs pointer">
          <button
            @click="isOnCompare ? goToComparePage() : addProductToCompare(product)"
            class="p0 inline-flex middle-xs bg-cl-white cl-orange brdr-none h5 pointer column-xs weight-400 "
            type="button"
            data-testid="addToCompare"
          >
            <i class="icon-compare size-icon" />
            <span class="weight-300 pt5 media-font">
              <template v-if="!isOnCompare">
                {{ $t('Add to compare') }}
              </template>
              <template v-else>
                {{ $t('Przejdź do porównania') }}
              </template>
            </span>
          </button>
        </div>
        <div class="col-xs-4 start-xs middle-xs column-xs pointer print-option" @click="printPage()">
          <i class="icon-ag_drukuj size-icon cl-primary-orange" />
          <span class="h5 cl-orange weight-300 pt5 media-font">{{ $t('Drukuj') }}</span>
        </div>
        <div class="col-xs-4 center-xs column-xs pointer wish-option">
          <add-to-wishlist :product="product" />
        </div>
        <div class="col-xs-4 center-xs flex column-xs pointer">
          <div class="no-underline flex middle-xs column-xs cl-orange"
               @click="$bus.$emit('modal-show', 'contact-modal')"
          >
            <i class="icon-phone-order size-icon" />
            <span class="h5 weight-300 pt5 media-font">{{ $t('Zamów telefonicznie') }}</span>
          </div>
        </div>
      </div>
    </no-ssr>
  </div>
</template>

<script>
// import Product from '@vue-storefront/core/pages/Product'
import AddToWishlist from 'src/themes/theme-aggrzejniki/components/core/blocks/Wishlist/AddToWishlist.vue'
import NoSSR from 'vue-no-ssr'
import i18n from '@vue-storefront/i18n'
import { IsOnCompare } from '@vue-storefront/core/modules/compare/components/IsOnCompare'
import { AddToCompare } from '@vue-storefront/core/modules/compare/components/AddToCompare.ts'

export default {
  name: 'ContactActions',
  components: {
    AddToWishlist,
    'no-ssr': NoSSR
  },
  mixins: [IsOnCompare, AddToCompare],
  props: {
    product: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    goToComparePage () {
      this.$router.push('/compare')
    },
    addProductToCompare () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: `Produkt ${this.product.name} został dodany do porównania!`,
        action1: { label: i18n.t('OK') },
        action2: { label: i18n.t('Przejdź do porównania'), action: 'toCompare' }
      }, { root: true })
      // Method renamed to 'addToCompare(product)', product is an Object
      AddToCompare.methods.addToCompare.call(this, this.product)
    },
    printPage () {
      return window.print()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$primary-orange: color(primary-orange);

.media-font {
  @media (max-width: 991px) {
    font-size: 14px
  }
}
.size-icon {
  font-size: 35px;
}
.print-option {
  display: flex;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    display: none !important;
  }
}
.wish-option {
  display: flex;
  @media (min-width: 767px) {
    display: none;
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    display: flex;
  }
}
.shadow-hover-elemet {
  margin-top: 15px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    margin: 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}
.column-xs {
  flex-direction: column;
}
.info-icon {
  font-size: 30px;
  color: $primary-orange;
}
</style>
