<template>
  <div class="description-container bg-cl-white mt15 px40 py15 align-justify">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'ProductDescription',
  props: {
    content: {
      type: String,
      required: true
    }
  }

}
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .description-container {
    padding: 0;
    margin-top: 20px;
  }
}
</style>
