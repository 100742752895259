export default {
  data () {
    return {
      valuesForpower_75_65_20: null,
      valuesForpower_70_55_20: null,
      valuesForwidth: null,
      valuesForheight: null
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-load', this.methodsCalledOnProductEnter)
    this.methodsCalledOnProductEnter()
  },
  beforeDestroy () {
    this.$bus.$off('product-after-load', this.methodsCalledOnProductEnter)
  },
  computed: {
    getMinMaxValueForpower_75_65_20 () {
      if (this.isAllAttrSelected || this.isAttributesUnblocked) {
        return this.getLabelforValue('power_75_65_20', this.getCurrentProduct['power_75_65_20']) + 'W'
      } else {
        return this.getMinMaxValueFor('power_75_65_20')
      }
    },
    getMinMaxValueForpower_70_55_20 () {
      if (this.isAllAttrSelected || this.isAttributesUnblocked) {
        return this.getLabelforValue('power_70_55_20', this.getCurrentProduct['power_70_55_20']) + 'W'
      } else {
        return this.getMinMaxValueFor('power_70_55_20')
      }
    },
    getMinMaxValueForwidth () {
      if (this.isAllAttrSelected || this.isAttributesUnblocked) {
        return this.getLabelforValue('width', this.getCurrentProduct['width']) + 'W'
      } else {
        return this.getMinMaxValueFor('width')
      }
    },
    getMinMaxValueForheight () {
      if (this.isAllAttrSelected || this.isAttributesUnblocked) {
        return this.getLabelforValue('height', this.getCurrentProduct['height']) + 'W'
      } else {
        return this.getMinMaxValueFor('height')
      }
    }
  },
  methods: {
    uniqueDistinctValues (data, field) {
      return [...new Set(data.map(prod => prod[field]))]
    },
    getMinMaxValueFor (type) {
      let allValues = this[`valuesFor${type}`]
      if (!allValues || !allValues.length) return null

      return (allValues.length === 1 && allValues[0])
        ? allValues[0] + 'W'
        : allValues[0] + ' - ' + allValues[allValues.length - 1]
    },
    methodsCalledOnProductEnter () {
      this.getValuesFor('power_75_65_20')
      this.getValuesFor('power_70_55_20')
      this.getValuesFor('width')
      this.getValuesFor('height')
    },
    getValuesFor (type) {
      let configurableChildren = this.getCurrentProduct.configurable_children
      if (!configurableChildren || !configurableChildren.length) this[`valuesFor${type}`] = null

      let uniqueDistinctValues = this.uniqueDistinctValues(this.getCurrentProduct.configurable_children, type)

      this[`valuesFor${type}`] = uniqueDistinctValues
        .map(value => Number(this.getLabelforValue(type, value)))
        .filter(num => num > 0)
        .sort((a, b) => a - b)
    }
  }
}
