<template>
  <button @click="isOnWishlist ? removeProductFromWhishList(product) : addProductToWhishlist(product)" class="disp-cont p0 inline-flex middle-xs bg-cl-transparent brdr-none action h5 pointer cl-secondary" type="button" data-testid="addToWishlist">
    <i class="icon-schowek cl-primary-orange icon-size" />
    <span class="weight-300 pt5 cl-orange">
      <template v-if="!isOnWishlist">
        {{ $t('Dodaj do schowka') }}
      </template>
      <template v-else>
        {{ $t('Usuń z schowka') }}
      </template>
    </span>
  </button>
</template>

<script>
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { AddToWishlist } from 'src/modules/vsf-magento-wishlist/components/AddToWishlist'
import { RemoveFromWishlist } from 'src/modules/vsf-magento-wishlist/components/RemoveFromWishlist'
import i18n from '@vue-storefront/i18n'
import { htmlDecode } from '@vue-storefront/core/lib/store/filters'

export default {
  mixins: [ IsOnWishlist, AddToWishlist, RemoveFromWishlist ],
  computed: {
    // favoriteIcon () {
    //   return this.isOnWishlist ? 'favorite' : 'favorite_border'
    // }
  },
  methods: {
    addProductToWhishlist (product) {
      // this.$store.dispatch('notification/spawnNotification', {
      //   type: 'success',
      //   message: `Produkt ${product.name} został dodany do schowka!`,
      //   action1: { label: i18n.t('OK') }
      // }, { root: true })
      this.addToWishlist(product)
    },
    removeProductFromWhishList (product) {
      // this.$store.dispatch('notification/spawnNotification', {
      //   type: 'success',
      //   message: `Produkt ${product.name} został usunięty ze schowka!`,
      //   action1: { label: i18n.t('OK') }
      // }, { root: true })
      this.removeFromWishlist(product)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$primary-orange: color(primary-orange);

.icon-size {
  font-size: 35px;
}
.disp-cont {
  display: contents;
}
.column-xs {
  flex-direction: column;
}
.info-icon {
  font-size: 30px;
  color: $primary-orange;
}
</style>
