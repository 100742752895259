<template>
  <div class="price">
    <div
      class="h3 cl-secondary custom-margines"
      v-if="initialPrice.special && initialPrice.default && initialPrice.original"
    >
      <span class="h2 cl-buccaneer weight-500">
        <div class="fs-medium" v-show="!isAllAttrSelected && !isAttributesUnblocked">
          Cena od:
        </div>
        <span v-if="minPrice && !isAllAttrSelected && !isAttributesUnblocked" class="block h4 original-price">
          {{ minPrice.original_price_incl_tax | price(storeView) }}
        </span>
        <span v-if="minPrice && !isAllAttrSelected && !isAttributesUnblocked">
          {{ minPrice.price_incl_tax | price(storeView) }}
        </span>
        <span v-show="isAllAttrSelected || isAttributesUnblocked" class="block h4 original-price">
          {{ price.original | price(storeView) }}
        </span>
        <span v-show="isAllAttrSelected || isAttributesUnblocked">
          {{ price.special | price(storeView) }}
        </span>
      </span>
      <p class="m0 cl-buccaneer flex h5 my5">
        {{ $t('Oszczędzasz') }}
        <span v-if="minPrice && !isAllAttrSelected && !isAttributesUnblocked" class="bg-cl-buccaneer cl-white h6 p3 ml5">
          -{{ 100 - Math.ceil(( minPrice.price_incl_tax / minPrice.original_price_incl_tax ) * 100) }} %
        </span>
        <span v-show="isAllAttrSelected || isAttributesUnblocked" class="bg-cl-buccaneer cl-white h6 p3 ml5">
          -{{ 100 - Math.ceil(( price.special / price.original ) * 100) }} %
        </span>
      </p>
      <!-- <p class="m0 cl-buccaneer flex h5 my5">
        Pospiesz się, została tylko {{ getCurrentProduct.stock.qty }} sztuka!
      </p> -->
    </div>
    <div
      class="h2 cl-black weight-700 custom-margines flex wrap middle-xs between-xs"
      v-if="!initialPrice.special && initialPrice.default"
    >
      <div class="fs-medium col-xs-12 p0 hidden-xs" v-if="minPrice && !isAllAttrSelected && !isAttributesUnblocked">
        Cena od:
      </div>
      <div class="price-font col-xs-12 p0 hidden-xs" v-if="minPrice && !isAllAttrSelected && !isAttributesUnblocked">
        {{ minPrice.price_incl_tax | price(storeView) }}
        <span class="fs-medium-small cl-secondary word-nowrap">
          {{ $t('Cena z VAT 23%') }}
        </span>
      </div>
      <div class="price-font col-xs-12 p0 hidden-xs" v-show="isAllAttrSelected || isAttributesUnblocked">
        {{ price.default | price(storeView) }}
        <span class="fs-medium-small cl-secondary word-nowrap">
          {{ $t('Cena z VAT 23%') }}
        </span>
      </div>
      <div class="col-xs-12 p0 visible-xs" :class="{'fs-medium': !isAllAttrSelected && !isAttributesUnblocked}">
        {{ !isAllAttrSelected && !isAttributesUnblocked ? 'Cena od:' : 'Cena wariantu:' }}
        <span v-if="minPrice && !isAllAttrSelected && !isAttributesUnblocked">
          {{ minPrice.price_incl_tax | price(storeView) }}
        </span>
        <span v-show="isAllAttrSelected || isAttributesUnblocked">
          {{ price.default | price(storeView) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { getCustomOptionValues, getCustomOptionPriceDelta } from '@vue-storefront/core/modules/catalog/helpers/customOption'
import { getBundleOptionsValues, getBundleOptionPrice } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions'
import get from 'lodash-es/get'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'ProductPrice',
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    customOptions: {
      type: Object,
      default: () => ({})
    },
    isAllAttrSelected: {
      type: Boolean,
      requred: false,
      default: false
    },
    minPrice: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    isAttributesUnblocked () {
      return this.$store.state.ui.isAttributesUnblocked
    },
    bundleOptionsPrice () {
      const allBundeOptions = this.product.bundle_options || []
      const selectedBundleOptions = Object.values(get(this.product, 'product_option.extension_attributes.bundle_options', {}))
      const price = getBundleOptionPrice(
        getBundleOptionsValues(selectedBundleOptions, allBundeOptions)
      )
      return price
    },
    customOptionsPriceDelta () {
      const priceDelta = getCustomOptionPriceDelta(
        getCustomOptionValues(Object.values(this.customOptions), this.product.custom_options),
        this.product
      )

      return priceDelta
    },
    price () {
      const customOptionPrice = this.customOptionsPriceDelta.priceInclTax
      const special = (this.initialPrice.default + customOptionPrice) * this.product.qty
      const original = (this.initialPrice.original + customOptionPrice) * this.product.qty
      const defaultPrice = this.product.qty > 0
        ? (this.initialPrice.default + customOptionPrice) * this.product.qty
        : this.initialPrice.default

      if (this.bundleOptionsPrice.priceInclTax > 0) {
        return {
          special,
          original,
          default: this.bundleOptionsPrice.priceInclTax
        }
      }

      return {
        special,
        original,
        default: defaultPrice
      }
    },
    initialPrice () {
      return {
        default: this.product.priceInclTax || 0,
        original: this.product.originalPriceInclTax || 0,
        special: this.product.specialPrice || 0
      }
    },
    storeView () {
      return currentStoreView()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);

.price-font {
  line-height: 30px;
  font-size: 32px;
  @media (max-width: 991px) {
    font-size: 30px;
  }
}
.price-original {
  text-decoration: line-through;
}
.custom-margines {
  margin-top: 10px;
  @media (max-width: 767px) {
    margin-top: 5px;
  }
}
.price {
  @media (max-width: 767px) {
    color: $color-primary;
  }
}
.original-price {
  display: block;
  color: #707070;
  text-decoration: line-through;
  font-weight: 400;
  font-size: 18px;
}
</style>
