import { mapGetters } from 'vuex'
import { getValueFor } from '@vue-storefront/core/helpers'

export default {
  data () {
    return {
      currentChildQty: 0
    }
  },
  watch: {
    'getCurrentProduct.sku': {
      handler () {
        this.isProductEcommerce()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration'
    }),
    valueForYes () {
      return getValueFor('ecommerce', 'yes')
    },
    valueForBoth () {
      return getValueFor('ecommerce', 'both')
    },
    allEccommerceAttr () {
      if (!this.getCurrentProduct.ecommerce) return null
      let isAvailable = (this.getCurrentProduct.ecommerce === this.valueForYes || this.getCurrentProduct.ecommerce === this.valueForBoth)
      if (!isAvailable) return null
      let allEcommerceChildren = this.getCurrentProduct.configurable_children.filter(children => children.stock.qty > 0)
      let parsedAttributes = {}
      for (let children of allEcommerceChildren) {
        for (let attr of Object.keys(this.getCurrentProductConfiguration)) {
          if (!parsedAttributes[attr]) {
            parsedAttributes[attr] = []
          }
          if (!parsedAttributes[attr].includes(children[attr].toString())) {
            parsedAttributes[attr].push(children[attr].toString())
          }
        }
      }
      return parsedAttributes
    }
  },
  methods: {
    isProductEcommerce () {
      if (this.getCurrentProduct.ecommerce) {
        let childEcomm = this.getCurrentProduct.configurable_children.filter(element => {
          if ((this.getCurrentProduct.ecommerce === this.valueForYes && element.stock.qty > 0) || (this.getCurrentProduct.ecommerce === this.valueForBoth && element.stock.qty > 0)) {
            return element
          }
        })
        if (childEcomm.length) {
          let currentProductConfiguration = this.getCurrentProductConfiguration
          let currentChild = this.getCurrentProduct.configurable_children.find(children => {
            return Object.keys(currentProductConfiguration).every((attr) => children[attr] === currentProductConfiguration[attr].id)
          })
          if (currentChild) {
            this.currentChildQty = currentChild.stock.qty
          } else {
            this.currentChildQty = 0
          }
        } else {
          this.currentChildQty = 0
        }
      } else {
        this.currentChildQty = 0
      }
    },
    isOnEcommerceAttr (type, id) {
      return this.allEccommerceAttr
        ? this.allEccommerceAttr && this.allEccommerceAttr[type] && this.allEccommerceAttr[type].includes(id)
        : false
    }
  }
}
