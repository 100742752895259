<template>
  <div id="product" class="bg-cl-secondary">
    <section class="product-top-section">
      <div id="breadcrumbContainer" class="container" :class="{'bg-cl-white-smoke' : isMobile }">
        <section class="row m0">
          <LazyHydrate when-idle>
            <breadcrumbs
              class="bg-cl-white-smoke desktop-padding"
              :class="{'cl-alternative' : !isMobile}"
            />
          </LazyHydrate>
        </section>
      </div>
      <div class="container">
        <section class="row m0 between-xs">
          <div class="col-md-8 col-tablet">
            <div class="row m0 bg-cl-white shadow-hover-elemet">
              <div class="top-section-tablet">
                <div class="col-xs-12 center-xs middle-xs relative">
                  <LazyHydrate never>
                    <div class="tile">
                      <span v-show="getCurrentProduct.parent_bestseller" class="tile__label uppercase cl-white bg-cl-alternative">
                        {{ $t('bestseller') }}
                      </span>
                      <span v-show="isNew" class="tile__label uppercase cl-white bg-cl-forest-green">
                        {{ $t('new') }}
                      </span>
                      <span v-show="isSale" class="tile__label uppercase cl-white bg-cl-orange">
                        {{ $t('special price') }}
                      </span>
                      <span v-show="getCurrentProduct.parent_hit_of_the_week" class="tile__label uppercase cl-white bg-cl-buccaneer">
                        {{ $t('hit_of_the_week') }}
                      </span>
                      <span v-show="getCurrentProduct.parent_hit_of_the_month" class="tile__label uppercase cl-white bg-cl-buccaneer">
                        {{ $t('hit_of_the_month') }}
                      </span>
                      <span v-show="getCurrentProduct.parent_hit_price" class="tile__label uppercase cl-white bg-cl-orange">
                        {{ $t('hit_price') }}
                      </span>
                    </div>
                  </LazyHydrate>
                  <LazyHydrate when-idle>
                    <product-gallery
                      :offline="getOfflineImage"
                      :gallery="getProductGallery"
                      :large="getProductLargeGallery"
                      :configuration="getCurrentProductConfiguration"
                      :product="getCurrentProduct"
                    />
                  </LazyHydrate>
                </div>
                <div class="show-tablet col-md-8 pt20">
                  <h1 class="mb0 mt0 cl-mine-shaft product-name h4" data-testid="productName">
                    {{ productName | htmlDecode }}
                  </h1>
                  <product-price
                    class="mb10 visible-xs-and-tablet"
                    v-if="getCurrentProduct.type_id !== 'grouped'"
                    :product="getCurrentProduct"
                    :custom-options="getCurrentCustomOptions"
                    :min-price="minPrice"
                  />
                  <div v-show="currentChildQty" class="mb15 uppercase h5 col-xs-8 cl-forest-green relative pl30 weight-500">
                    <i class="cl-forest-green check-icon">
                      &#10003;
                    </i>
                    <span>{{ $t('Produkt dostępny od ręki') }}</span>
                  </div>
                  <div class="xspy15">
                    <div :class="{'row middle-xs': isMobile}">
                      <div :class="{'col-xs-8': isMobile}">
                        <p class="my10" v-show="getCurrentProduct.manufacturer">
                          <span class="weight-700 cl-black">{{ $t('Producent') }}:</span> {{ getLabelforValue('manufacturer', getCurrentProduct.manufacturer) }}
                        </p>
                        <p class="my10" v-show="getCurrentProduct.model">
                          <span class="weight-700 cl-black">{{ $t('Model') }}:</span> {{ getCurrentProduct.model }}
                        </p>
                      </div>
                    </div>
                    <p class="my10 flex relative middle-xs connection-style" :class="{'mt0': isMobile}" v-show="getCurrentProduct.connection_code">
                      <span class="weight-700 cl-black flex pr5 middle-xs">{{ $t('Kod podłączenia') }}
                        <span v-show="imageResultResponse" @click="$bus.$emit('modal-show', 'product-info-modal'), changeModalText('connectionCode')" class="flex middle-xs">&nbsp;
                          <i class="icon-info pointer icon-position-mobile cl-primary-orange" />
                        </span>:
                      </span> {{ getLabelArraysforValues('connection_code', getCurrentProduct.connection_code) }}
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.power_75_65_20">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('Moc grzewcza') }}
                        <span @click="$bus.$emit('modal-show', 'product-info-modal'), changeModalText('power75')" class="flex middle-xs">&nbsp;
                          <i class="icon-info pointer icon-position-mobile cl-primary-orange" />:
                        </span>
                      </span>
                      {{ getMinMaxValueForpower_75_65_20 }} (75/65/20)
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.power_70_55_20">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('Moc grzewcza') }}
                        <span @click="$bus.$emit('modal-show', 'product-info-modal'), changeModalText('power70')" class="flex middle-xs">&nbsp;
                          <i class="icon-info pointer icon-position-mobile cl-primary-orange" />:
                        </span>
                      </span>
                      {{ getMinMaxValueForpower_70_55_20 }} (70/55/20)
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.water_capacity">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('water_capacity') }}:
                      </span>
                      {{ getLabelforValue('water_capacity', getCurrentProduct.water_capacity) }} l
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.weight">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('Weight') }}:
                      </span>
                      {{ getCurrentProduct.weight }} kg
                    </p>
                    <p class="my10" v-show="getCurrentProduct.guarantee">
                      <span class="weight-700 cl-black">{{ $t('Gwarancja producenta') }}:</span> {{ getCurrentProduct.guarantee }}
                    </p>
                  </div>
                  <LazyHydrate when-visible>
                    <related-small type="related" v-show="getCurrentProduct.product_links" :current-product="getCurrentProduct" />
                  </LazyHydrate>
                </div>
              </div>
              <div class="col-xs-12 col-md-5 data mt20 col-tablet">
                <LazyHydrate when-visible>
                  <product-meta :active-length="activeConfigurableChildrens.length" :min-price="minPrice" :max-price="maxPrice" :product="getCurrentProduct" :aggregate-rating="reviewsStar" :review-count="reviewsCount" :review-arr="reviewArr" :gallery="getUniqProductGallery" />
                </LazyHydrate>
                <h1 class="hidde-tablet mb0 mt0 cl-mine-shaft product-name h4" data-testid="productName">
                  {{ productName | htmlDecode }}
                </h1>
                <!-- <img src="/assets/PayU_logo.svg" alt="payu logo" height="35px" width="auto" class="">
                <p class="m0">
                  <span id="installment-mini" /> -->
                <!-- </p> -->
                <!-- <web-share :title="productName | htmlDecode" text="Check this product!" class="web-share" /> -->
                <product-price
                  class="mb10 visible-xs"
                  v-if="getCurrentProduct.type_id !== 'grouped'"
                  :product="getCurrentProduct"
                  :custom-options="getCurrentCustomOptions"
                  :min-price="minPrice"
                />
                <div class="row visible-xs" v-show="getCurrentProduct.parent_free_valves && getCurrentProduct.darmowe_zawory_block">
                  <div class="col-xs-12 ">
                    <div @click="$bus.$emit('modal-show', 'modal-zawory')" class="label-free relative">
                      <span class="bg-cl-forest-green cl-white weight-200 p3 w-100 align-center pointer">Zawory gratis!<i class="icon-info pointer pl5 icon-zawory icon-position-mobile-zawory cl-primary-orange" /></span>
                    </div>
                  </div>
                </div>
                <div class="row visible-xs" v-show="reviewsStar">
                  <div class="col-xs-12">
                    <span class="star-rating flex middle-xs between-xs wrap">
                      <div class="flex middle-xs pb5">
                        <span class="cl-yellow h4 mr5">{{ reviewsStar }}</span>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 1">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 2">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 3">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 4">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 5">&#9733;</i>
                        <span v-if="reviewsCount >= 1" class="cl-yellow fs13 pl5 weight-300">
                          ({{ reviewsCount }})
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-xs-12 p0 flex middle-xs visible-xs">
                  <p class="m0">
                    <span id="installment-mini-mobile" />
                  </p>
                  <img src="/assets/PayU_logo.svg" alt="payu logo" height="35px" width="auto" class="">
                </div>
                <div class="hidden-xs-and-tablet mt5 mb15 cl-secondary h6">
                  {{ $t('Numer referencyjny') }} {{ getCurrentProduct.sku }}
                </div>
                <div class="visible-xs">
                  <div class="row m0 pb10">
                    <p class="m0 p0 fs-medium-small cl-matterhorn col-xs-6">
                      {{ $t('Cena z VAT 23%') }}
                    </p>
                    <p v-show="currentChildQty" class="m0 cl-forest-green col-xs-6 product-dostepny align-right">
                      {{ $t('Produkt dostępny') }}
                    </p>
                    <button
                      @click="scrollTo()"
                      class="available-variation-button align-center m0 mt10 col-xs-8 cl-primary-orange bg-cl-white"
                    >
                      Zobacz dostępne opcje
                    </button>
                  </div>
                </div>
                <div class="divider bg-cl-white-smoke visible-xs" />
                <div v-show="currentChildQty" class="mb15 uppercase h5 col-xs-8 cl-forest-green relative pl30 weight-500 hidden-xs-and-tablet">
                  <i class="cl-forest-green check-icon">
                    &#10003;
                  </i>
                  <span>{{ $t('Produkt dostępny od ręki') }}</span>
                </div>
                <div
                  class="variants-tablet"
                  v-if="getCurrentProduct.type_id =='configurable'"
                >
                  <div class="error" v-if="getCurrentProduct.errors && Object.keys(getCurrentProduct.errors).length > 0">
                    {{ getCurrentProduct.errors | formatProductMessages }}
                  </div>
                  <div class="xspy15 hidde-tablet">
                    <div :class="{'row middle-xs': isMobile}">
                      <div :class="{'col-xs-8': isMobile}">
                        <p class="my10" v-show="getCurrentProduct.manufacturer">
                          <span class="weight-700 cl-black">{{ $t('Producent') }}:</span> {{ getLabelforValue('manufacturer', getCurrentProduct.manufacturer) }}
                        </p>
                        <p class="my10" v-show="getCurrentProduct.model">
                          <span class="weight-700 cl-black">{{ $t('Model') }}:</span> {{ getCurrentProduct.model }}
                        </p>
                      </div>
                    </div>
                    <p class="my10 flex relative middle-xs connection-style" :class="{'mt0': isMobile}" v-show="getCurrentProduct.connection_code">
                      <span class="weight-700 cl-black flex pr5 middle-xs">{{ $t('Kod podłączenia') }}
                        <span v-show="imageResultResponse" @click="$bus.$emit('modal-show', 'product-info-modal'), changeModalText('connectionCode')" class="flex middle-xs">&nbsp;
                          <i class="icon-info pointer icon-position-mobile cl-primary-orange" />
                        </span>:
                      </span> {{ getLabelArraysforValues('connection_code', getCurrentProduct.connection_code) }}
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.power_75_65_20">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('Moc grzewcza') }}
                        <span @click="$bus.$emit('modal-show', 'product-info-modal'), changeModalText('power75')" class="flex middle-xs">&nbsp;
                          <i class="icon-info pointer icon-position-mobile cl-primary-orange" />:
                        </span>
                      </span>
                      {{ getMinMaxValueForpower_75_65_20 }} (75/65/20)
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.power_70_55_20">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('Moc grzewcza') }}
                        <span @click="$bus.$emit('modal-show', 'product-info-modal'), changeModalText('power70')" class="flex middle-xs">&nbsp;
                          <i class="icon-info pointer icon-position-mobile cl-primary-orange" />:
                        </span>
                      </span>
                      {{ getMinMaxValueForpower_70_55_20 }} (70/55/20)
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.water_capacity">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('water_capacity') }}:
                      </span>
                      {{ getLabelforValue('water_capacity', getCurrentProduct.water_capacity) }} l
                    </p>
                    <p class="my10 flex relative middle-xs" v-show="getCurrentProduct.weight">
                      <span class="weight-700 cl-black flex pr5  middle-xs">{{ $t('Weight') }}:
                      </span>
                      {{ getCurrentProduct.weight }} kg
                    </p>
                    <p class="my10" v-show="getCurrentProduct.guarantee">
                      <span class="weight-700 cl-black">{{ $t('Gwarancja producenta') }}:</span> {{ getCurrentProduct.guarantee }}
                    </p>
                  </div>
                  <LazyHydrate when-visible>
                    <related-small class="hidde-tablet" type="related" v-show="getCurrentProduct.product_links" :current-product="getCurrentProduct" />
                  </LazyHydrate>
                  <div id="scrollTo" class="divider bg-cl-white-smoke visible-xs-and-tablet" />
                  <product-info-modal :text="selectedText" :prod="getCurrentProduct" />
                  <color-wariant-modal />
                  <product-links
                    v-if="getCurrentProduct.type_id =='grouped'"
                    :products="getCurrentProduct.product_links"
                  />
                  <product-custom-color
                    v-if="isColorOption"
                    :product="getCurrentProduct"
                  />
                  <product-bundle-options
                    v-if="getCurrentProduct.bundle_options && getCurrentProduct.bundle_options.length > 0"
                    :product="getCurrentProduct"
                  />
                  <product-custom-options
                    v-else-if="getCurrentProduct.custom_options && getCurrentProduct.custom_options.length > 0"
                    :product="getCurrentProduct"
                  />
                  <div
                    class="h5"
                    v-show="sortedEcommerceVariants.length === 0"
                    v-for="option in getProductOptions"
                    :key="option.attribute_id"
                  >
                    <div class="row top-xs m0 pb10 variants-wrapper relative">
                      <p v-if="option.label === 'length'" class="my5 cl-black col-xs-6 p0 weight-700">
                        {{ $t(option.label + '1') }}
                      </p>
                      <p v-else class="my5 cl-black col-xs-6 p0 weight-700">
                        {{ $t(option.label) }}
                      </p>
                      <div v-show="!isHideAttr.includes(option.attribute_code) && isMoreThan5Elem(option.attribute_code)" @click="changeHideAttr(option.attribute_code)" class="hidden-xs-and-tablet show-button col-xs-6 p0 my5 pointer">
                        pokaż wszystkie
                      </div>
                      <div v-if="option.attribute_code === 'color'" class="colors col-xs-12 p0 flex wrap" :class="{ 'hidden-attrinute' : !isHideAttr.includes(option.attribute_code) && !isTablet }">
                        <color-selector-product
                          class="mr10 mb10"
                          v-for="filter in sortById(getAvailableFilters[option.attribute_code])"
                          :key="filter.id"
                          :variant="filter"
                          :label="filter.label"
                          :brand="getLabelforValue('manufacturer', getCurrentProduct.manufacturer)"
                          :palette="getLabelsfor('palette_id')"
                          :selected-filters="selectedAttr.includes(option.attribute_code) || isAttributesUnblocked ? getSelectedFilters : {}"
                          @change="changeFilter"
                          :class="[
                            { 'disabled': !isOptionAvailable(filter) },
                            { 'not-eccomerce' : isRelatedInProductLinks && !isOnEcommerceAttr(filter.type, filter.id) && currentRelatedTab && getLabelforValue('ecommerce', getCurrentProduct.ecommerce) === 'both' }
                          ]"
                        />
                      </div>
                      <div v-else class="sizes col-xs-12 p0" :class="{ 'hidden-attrinute' : !isHideAttr.includes(option.attribute_code) && !isTablet }">
                        <size-selector
                          class="mr10 mb10"
                          v-for="filter in sortById(getAvailableFilters[option.attribute_code])"
                          :key="filter.id"
                          :variant="filter"
                          :label="filter.label"
                          :selected-filters="selectedAttr.includes(option.attribute_code) || isAttributesUnblocked ? getSelectedFilters : {}"
                          @change="changeFilter"
                          :class="[
                            { 'disabled' : !isOptionAvailable(filter) },
                            { 'not-eccomerce' : isRelatedInProductLinks && !isOnEcommerceAttr(filter.type, filter.id) && currentRelatedTab && getLabelforValue('ecommerce', getCurrentProduct.ecommerce) === 'both' }
                          ]"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-show="sortedEcommerceVariants.length > 0">
                    <div class="ecommerce-products-header row">
                      <div class="variant col-xs-3 flex middle-xs pointer align-left" :class="{'active': sortedCodeOrder.code === option.attribute_code }" v-for="option in getProductOptions" :key="option.attribute_id + 'ecommerce-header'" @click="sortVariantsInStock(option.attribute_code)">
                        {{ $t(option.label) }} <span class="flex wrap pl5"><i class="icon-top py5 fs8px" /><i class="icon-bottom py5 fs8px" /></span>
                      </div>
                    </div>
                    <div class="ecommerce-products" :class="{ 'opened' : isEcommerceVariantsOpened }">
                      <div v-for="variant in sortedEcommerceVariants" :key="variant.id" class="variants-box row" :class="{'active': variant.sku === getCurrentProduct.sku && isAttributesUnblocked}" @click="selectVariant(variant)">
                        <!-- <div class="col-xs-2" v-if="variant.image">
                          <img :src="getSmallThumbnail(variant.image)">
                        </div> -->
                        <div class="variant col-xs-3" v-for="option in getProductOptions" :key="option.attribute_id + variant.id">
                          <p class="m0" v-if="option.attribute_code === 'color'">
                            <color-selector-product
                              class="variant-color"
                              :variant="{ label: getLabelforValue(option.attribute_code, variant[option.attribute_code]) }"
                              :label="getLabelforValue(option.attribute_code, variant[option.attribute_code])"
                              :brand="getLabelforValue('manufacturer', getCurrentProduct.manufacturer)"
                              :palette="getLabelsfor('palette_id')"
                            />
                          </p>
                          <p class="m0" v-else>
                            {{ getLabelforValue(option.attribute_code, variant[option.attribute_code]) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ecommerce-products-footer" :class="{ 'opened' : isEcommerceVariantsOpened }" @click="isEcommerceVariantsOpened = true">
                      pokaż więcej <i class="icon-bottom ml5 fs8px" />
                    </div>
                  </div>
                  <div class="row m0">
                    <div class="col-xs-12 visible-xs-and-tablet wrap" v-show="isAllAttrSelected || isAttributesUnblocked">
                      <product-price
                        class="mb10 w-100"
                        v-if="getCurrentProduct.type_id !== 'grouped'"
                        :product="getCurrentProduct"
                        :custom-options="getCurrentCustomOptions"
                        :is-all-attr-selected="isAllAttrSelected"
                        :min-price="minPrice"
                      />
                      <div class="col-xs-12 p0 flex middle-xs visible-xs">
                        <p class="m0">
                          <span id="installment-mini-mobile2" />
                        </p>
                        <img src="/assets/PayU_logo.svg" alt="payu logo" height="35px" width="auto">
                      </div>
                      <div class="selected-producted-tabs w-100">
                        <p class="selected-title m0 mb10 center-xs">
                          Wybrany wariant
                        </p>
                        <div class="container-selected-options">
                          <div class="opt-t">
                            <p v-for="(item, index) in getCurrentCustomOptions" class="m0 mb10 mr10 cl-primary" :key="index + 'custom'">
                              {{ $t(getLabelForCustomOptions(item.option_id)) }}:
                              <span class="pl5">
                                {{ getValueLabelForCustomOption(item.option_id, item.option_value) }};
                              </span>
                            </p>
                          </div>
                          <div class="opt-t" v-if="getCurrentProduct.options">
                            <p v-for="(option, index) in getCurrentProduct.options" class="m0 mb10 mr10 cl-primary" :key="index + 'option'">
                              {{ $t(option.label) }}:
                              <span class="pl5">{{ option.value }};</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 visible-xs-and-tablet p0">
                      <add-to-cart
                        @click-add-to-cart="validateAddToCart()"
                        :product="getCurrentProduct"
                        :disabled="$v.product.qty.$error && !$v.product.qty.minValue || (!isAllAttrSelected && !isAttributesUnblocked)"
                        class="row m0 tablet-add-to-cart"
                      />
                    </div>
                    <div class="row mx0 visible-xs-and-tablet" v-show="!isAllAttrSelected && !isHideValidation && !isAttributesUnblocked">
                      <div class="col-xs-12">
                        <p class="my5 cl-error h6">
                          {{ $t('Dokończ konfigurację produktu. Zaznacz odpowiednie opcje w polach:') }}
                          <span v-for="(type, index) of notSelectedAttr" :key="index + '_type'">
                            {{ $t(type) }}<span v-show="index + 1 !== notSelectedAttr.length">, </span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div v-show="getCurrentProduct.delivery_time && !currentChildQty" class="col-xs-12 visible-xs-and-tablet fs-medium-small my10 px0">
                      <span class="cl-primary">{{ $t('Szacowany czas realizacji:') }}&nbsp;</span>
                      <span class="weight-600 cl-primary">{{ getCurrentProduct.delivery_time }} dni roboczych</span>
                    </div>
                  </div>
                  <product-description class="visible-xs-and-tablet" v-if="getCurrentProduct.product_description" :content="getCurrentProduct.product_description" />
                  <contact-actions class="visible-xs-and-tablet" :product="getCurrentProduct" />
                  <div class="divider bg-cl-white-smoke visible-xs" />
                  <delivery-info v-show="isMobile" :get-current-product="getCurrentProduct" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 media-padding-right hidden-xs-and-tablet">
            <div class="row m0" ref="stickyContainer">
              <div id="stickyBox" class="col-xs-12 bg-cl-white stickyBox pb15"
                   :class="[{ 'fixedWithScroll': !bottom && productHasScroll}, { 'bottom-fixed' : bottom && productHasScroll }]"
                   :style="{ top: heightFromTop + 'px', width: calculateWidth() + 'px' }"
              >
                <div v-show="productHasScroll && isShowStickyImg()" class="relative flex middle-xs image-container mt5">
                  <p v-if="getCurrentProduct.special_price"
                     class="absolute uppercase bg-cl-buccaneer cl-white weight-100 py3 px10 promocja"
                  >
                    promocja
                  </p>
                  <img
                    class="w-auto stickyContainer-image"
                    :alt="getCurrentProduct.getCurrentProduct ? getCurrentProduct.name : 'product image'"
                    v-lazy="getThumbnail(getCurrentProduct.image)"
                  >
                </div>

                <div>
                  <product-price
                    class="mb5"
                    v-if="getCurrentProduct.type_id !== 'grouped'"
                    :product="getCurrentProduct"
                    :custom-options="getCurrentCustomOptions"
                    :is-all-attr-selected="isAllAttrSelected"
                    :min-price="minPrice"
                  />
                </div>
                <div class="row mb5" v-show="minPrice && minPrice.price_incl_tax >= 300 && minPrice.price_incl_tax <= 20000">
                  <div class="col-xs-12 flex middle-xs">
                    <p class="m0">
                      <span id="installment-mini" />
                    </p>
                    <img src="/assets/PayU_logo.svg" alt="payu logo" height="35px" width="auto" class="">
                  </div>
                </div>
                <div class="row" v-show="getCurrentProduct.parent_free_valves && getCurrentProduct.darmowe_zawory_block">
                  <div class="col-xs-12 ">
                    <div @click="$bus.$emit('modal-show', 'modal-zawory')" class="label-free">
                      <span class="bg-cl-forest-green cl-white weight-200 p3 w-100 align-center pointer">Zawory gratis!<i class="icon-info pointer pl5 icon-zawory icon-position-mobile cl-primary-orange" /></span>
                    </div>
                  </div>
                </div>
                <div class="row" v-show="reviewsStar">
                  <div class="col-xs-12">
                    <span class="star-rating flex middle-xs between-xs wrap">
                      <div class="flex middle-xs pb5">
                        <span class="cl-yellow h4 mr5">{{ reviewsStar }}</span>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 1">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 2">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 3">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 4">&#9733;</i>
                        <i class="cl-yellow fs13" v-if="reviewsStar >= 5">&#9733;</i>
                        <span v-if="reviewsCount >= 1" class="cl-yellow fs13 pl5 weight-300">
                          ({{ reviewsCount }})
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <delivery-info :get-current-product="getCurrentProduct" />
                </div>
                <div class="row m0 mb10 middle-xs" v-if="getCurrentProduct.type_id !== 'grouped' && getCurrentProduct.type_id !== 'bundle'">
                  <div>
                    <product-quantity
                      class="row m0"
                      v-if="getCurrentProduct.type_id !== 'grouped' && getCurrentProduct.type_id !== 'bundle'"
                      v-model="getCurrentProduct.qty"
                      :max-quantity="maxQuantity"
                      :loading="isStockInfoLoading"
                      :is-simple-or-configurable="isSimpleOrConfigurable"
                      :show-quantity="manageQuantity"
                      :check-max-quantity="manageQuantity"
                      :current-child-qty="currentChildQty"
                      @click="updateProductQty"
                      @error="handleQuantityError"
                    />
                  </div>
                  <div v-show="isAllAttrSelected || isAttributesUnblocked">
                    <span class="cl-matterhorn" v-show="getCurrentProduct.stock.qty > 0">{{ productsLeft }}</span>
                  </div>
                </div>
                <div v-show="(getCurrentProduct.options && productHasScroll && isAllAttrSelected) || isAttributesUnblocked">
                  <p v-for="(item, index) in getCurrentCustomOptions" class="fs-15 m0 mb5 cl-primary" :key="index + 'custom'">
                    {{ $t(getLabelForCustomOptions(item.option_id)) }}: <span class="pl5">{{ getValueLabelForCustomOption(item.option_id, item.option_value) }}</span>
                  </p>
                  <p v-for="(option, index) in getCurrentProduct.options" class="fs-15 m0 mb5 cl-primary" :key="index + 'option'">
                    {{ $t(option.label) }}:
                    <span class="pl5">{{ option.value }}</span>
                  </p>
                </div>
                <add-to-cart-with-wishlist
                  @click-add-to-cart="validateAddToCart()"
                  :product="getCurrentProduct"
                  :disabled="($v.product.qty.$error && !$v.product.qty.minValue) || (!isAllAttrSelected && !isAttributesUnblocked) || isAddToCartDisabled"
                  class="row mt15 mb5"
                />
                <div class="row mx0" v-show="!isAllAttrSelected && !isHideValidation && !isAttributesUnblocked">
                  <div class="col-xs-12">
                    <p class="my5 cl-error h6">
                      {{ $t('Dokończ konfigurację produktu. Zaznacz odpowiednie opcje w polach:') }}
                      <span v-for="(type, index) of notSelectedAttr" :key="index + '_type'">
                        {{ $t(type) }}<span v-show="index + 1 !== notSelectedAttr.length">, </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div v-show="getCurrentProduct.delivery_time && !currentChildQty" class="row mx0 fs-medium-small">
                  <span class="cl-primary">{{ $t('Szacowany czas realizacji:') }}&nbsp;</span>
                  <span class="weight-600 cl-primary">{{ getCurrentProduct.delivery_time }} dni roboczych</span>
                </div>
              </div>
              <product-description v-if="getCurrentProduct.product_description" :content="getCurrentProduct.product_description" />
              <contact-actions :product="getCurrentProduct" />
            </div>
          </div>
        </section>
        <modal :modal-size="true" :name="'modal-zawory'">
          <div slot="header">
            Darmowe zawory
          </div>
          <div slot="content" class="table-box">
            <p v-html="cmsBlockSunerzhaZawory" />
          </div>
        </modal>
      </div>
    </section>
    <div
      v-observe-visibility="{
        callback: visibilityChanged,
        once: true
      }"
    >
      <product-tabs v-if="seen" class="mt30" :prod="getCurrentProduct" />
      <related-products v-if="seen" type="upsell" :heading="$t('Similar products')" :category-id="getLastCategoryId()" />
      <related-bestseller v-if="seen" type="bestseller" :heading="$t('Bestsellers')" :category-id="getLastCategoryId()" />
      <blog-preview-product v-if="seen" />
    </div>
  </div>
</template>

<script>
import { minValue } from 'vuelidate/lib/validators'
import { MobileDetected } from '../components/core/mobileDetected.ts'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import VueOfflineMixin from 'vue-offline/mixin'
import AddToCart from 'theme/components/core/AddToCart.vue'
import AddToCartWithWishlist from 'theme/components/core/AddToCartWithWishlist.vue'
import SizeSelector from 'theme/components/core/SizeSelector.vue'
import Breadcrumbs from 'theme/components/core/Breadcrumbs.vue'
import ProductLinks from 'theme/components/core/ProductLinks.vue'
import ProductBundleOptions from 'theme/components/core/ProductBundleOptions.vue'
import ProductCustomOptions from 'theme/components/core/ProductCustomOptions.vue'
import ProductGallery from 'theme/components/core/ProductGallery'
import focusClean from 'theme/components/theme/directives/focusClean'
import ProductQuantity from 'theme/components/core/ProductQuantity.vue'
import ContactActions from 'theme/components/core/blocks/Product/ContactActions.vue'
import DeliveryInfo from 'theme/components/core/blocks/Product/DeliveryInfo.vue'
import ProductDescription from 'theme/components/core/blocks/Product/ProductDescription.vue'
import config from 'config'
import Modal from 'theme/components/core/Modal.vue'
import { mapGetters } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import { ReviewModule } from '@vue-storefront/core/modules/review'
import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption.ts'
import { getAvailableFiltersByProduct, getSelectedFiltersByProduct } from '@vue-storefront/core/modules/catalog/helpers/filters'
import { isOptionAvailableAsync } from '@vue-storefront/core/modules/catalog/helpers/index'
import { localizedRoute, currentStoreView } from '@vue-storefront/core/lib/multistore'
import { htmlDecode } from '@vue-storefront/core/filters'
import { RecentlyViewedModule } from '@vue-storefront/core/modules/recently-viewed'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { onlineHelper, isServer } from '@vue-storefront/core/helpers'
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks'
import { InstantCheckoutModule } from 'src/modules/vsf-instant-checkout'
import ProductPrice from 'theme/components/core/ProductPrice.vue'
import ProductMeta from 'theme/components/core/blocks/Product/ProductMeta.vue';
import isEcommerceMixin from 'theme/mixins/isEcommerceMixin.ts'
import minMaxValueMixin from 'theme/mixins/product/minMaxValueMixin.ts'
import getLabelMixin from 'theme/mixins/getLabelMixin.ts'
// import sanitizeHtml from 'sanitize-html'

const RelatedSmall = () => import(/* webpackChunkName: "vsf-related-small" */ 'theme/components/core/blocks/Product/RelatedSmall.vue');
const ColorSelectorProduct = () => import(/* webpackChunkName: "vsf-color-selector-product" */ 'theme/components/core/ColorSelectorProduct.vue');
const RelatedProducts = () => import(/* webpackChunkName: "vsf-related" */ 'theme/components/core/blocks/Product/Related.vue');
const RelatedBestseller = () => import(/* webpackChunkName: "vsf-related-bestseller" */ 'theme/components/core/blocks/Product/RelatedBestseller.vue');
const ProductTabs = () => import(/* webpackChunkName: "vsf-product-tabs" */ 'theme/components/core/blocks/ProductTabs/ProductTabs.vue');
const BlogPreviewProduct = () => import(/* webpackChunkName: "vsf-blog-preview-product" */ 'theme/components/core/BlogPreviewProduct.vue');
const ProductCustomColor = () => import(/* webpackChunkName: "vsf-product-custom-color" */ 'theme/components/core/ProductCustomColor.vue');
const ColorWariantModal = () => import(/* webpackChunkName: "vsf-color-wariant-modal" */ 'theme/components/core/blocks/Product/ColorWariantModal.vue');
const ProductInfoModal = () => import(/* webpackChunkName: "vsf-product-info-modal" */ 'theme/components/core/blocks/Product/ProductInfoModal.vue');

export default {
  components: {
    RelatedBestseller,
    Breadcrumbs,
    ProductBundleOptions,
    ProductCustomOptions,
    ProductCustomColor,
    ProductGallery,
    ProductLinks,
    RelatedProducts,
    SizeSelector,
    ProductQuantity,
    ProductTabs,
    ProductInfoModal,
    ColorWariantModal,
    AddToCartWithWishlist,
    AddToCart,
    Modal,
    ContactActions,
    BlogPreviewProduct,
    DeliveryInfo,
    LazyHydrate,
    ProductPrice,
    ColorSelectorProduct,
    RelatedSmall,
    ProductMeta,
    ProductDescription
  },
  mixins: [ProductOption, VueOfflineMixin, MobileDetected, isEcommerceMixin, minMaxValueMixin, getLabelMixin],
  directives: { focusClean },
  beforeCreate () {
    registerModule(ReviewModule)
    registerModule(RecentlyViewedModule)
    registerModule(InstantCheckoutModule)
  },
  data () {
    return {
      timer: null,
      seen: false,
      selectedAttr: [],
      maxQuantity: 0,
      quantityError: false,
      isStockInfoLoading: false,
      hasAttributesLoaded: false,
      manageQuantity: true,
      // isShowStickyImg: true,
      detailsOpen: false,
      scrollTop: 0,
      lastScrollTop: 0,
      bottom: false,
      heightFromTop: '',
      productHasScroll: false,
      tipFromTop: '',
      calculatedWidth: 0,
      isInstantCheckoutRegistered: this.$store.getters['instant-checkout/getSupported'],
      selectedText: '',
      isHideAttr: [],
      isHideValidation: true,
      sortedEcommerceVariants: [],
      sortedCodeOrder: {},
      isEcommerceVariantsOpened: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getCurrentProduct: 'product/getCurrentProduct',
      getProductGallery: 'product/getProductGallery',
      getProductLargeGallery: 'product/getProductLargeGallery',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration',
      getOriginalProduct: 'product/getOriginalProduct',
      attributesByCode: 'attribute/attributeListByCode',
      getCurrentCustomOptions: 'product/getCurrentCustomOptions',
      cmsBlocks: 'cmsBlock/cmsBlocks'
    }),
    activeConfigurableChildrens () {
      if (!this.getCurrentProduct.configurable_children || !this.getCurrentProduct.configurable_children.length) return []
      return this.getCurrentProduct.configurable_children
        .filter(child => child.price_incl_tax && child.stock.is_in_stock === true)
    },
    minPrice () {
      if (!this.activeConfigurableChildrens || !this.activeConfigurableChildrens.length) return null
      return this.activeConfigurableChildrens
        .reduce((prev, curr) => {
          return (prev.price_incl_tax < curr.price_incl_tax)
            ? prev
            : curr
        }, 0)
    },
    maxPrice () {
      if (!this.activeConfigurableChildrens || !this.activeConfigurableChildrens.length) return null
      return this.activeConfigurableChildrens
        .reduce((prev, curr) => {
          return (prev.price_incl_tax > curr.price_incl_tax)
            ? prev
            : curr
        }, 0)
    },
    isRelatedInProductLinks () {
      return this.getCurrentProduct.product_links && this.getCurrentProduct.product_links.some(link => link.link_type === 'related')
    },
    cmsBlockSunerzhaZawory () {
      return this.cmsBlocks.find(x => x.identifier === this.getCurrentProduct.darmowe_zawory_block) ? this.cmsBlocks.find(x => x.identifier === this.getCurrentProduct.darmowe_zawory_block).content : ''
    },
    productName () {
      return this.getCurrentProduct.h1 ? this.getCurrentProduct.h1 : this.getCurrentProduct.name
    },
    isSale () {
      return this.getCurrentProduct.configurable_children.some(children => children.special_price > 0)
    },
    isNew () {
      const currentDate = new Date()
      // eslint-disable-next-line no-trailing-spaces
      let expireDate = this.getCurrentProduct.news_to_date 
        ? Date.parse(this.getCurrentProduct.news_to_date)
        : 'none'
      return ((expireDate !== 'none') && (currentDate.getTime() < expireDate))
    },
    isAttributesUnblocked () {
      return this.$store.state.ui.isAttributesUnblocked
    },
    notSelectedAttr () {
      let customOptionLabels = this.getCurrentProduct.custom_options ? this.getCurrentProduct.custom_options.map(option => option.title) : []
      let allOptions = this.getCurrentProduct.parent_simples_alternative_select ? customOptionLabels : [...Object.keys(this.getAvailableFilters), ...customOptionLabels]

      let selectedOptions = this.selectedOptions
      return allOptions
        ? allOptions.filter(x => !selectedOptions.includes(x))
        : []
    },
    productsLeft () {
      if (this.getCurrentProduct.stock.qty === 1) {
        return `Ostatnia sztuka: ${this.getCurrentProduct.stock.qty}`
      } else if (this.getCurrentProduct.stock.qty > 1) {
        return `Pozostało tylko: ${this.getCurrentProduct.stock.qty}`
      } else {
        return ''
      }
    },
    currentRelatedTab () {
      return this.$store.state.ui.currentTab
    },
    imageResultResponse () {
      return this.$store.state.ui.imageResultResponse
    },
    chosenColor () {
      return this.$store.state.ui.chosenColor
    },
    reviews () {
      return this.$store.state.review
    },
    reviewArr () {
      return this.reviews ? this.reviews.items.items : []
    },
    reviewsCount () {
      return this.reviews && this.reviews.items.total
        ? this.reviews.items.total.value
        : 0
    },
    reviewsStar () {
      if (this.reviews && this.reviews.items && this.reviews.items.items) {
        let temp = this.reviews.items.items.map((item) => item.ratings && item.ratings.length ? item.ratings[0].value : 0)
        let notNegative = temp.filter((item) => item > 0)
        if (notNegative.length) {
          return temp.reduce((a, b, index) => a + b, 0) / notNegative.length
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    isColorOption () {
      return this.getCurrentProduct.custom_options && this.getCurrentProduct.custom_options.length > 0
        ? this.getCurrentProduct.custom_options.filter(opt => opt.title === 'color').length
        : 0
    },
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    isBlocked () {
      return this.$store.state.ui.isColorBlocked
    },
    getUniqProductGallery () {
      return this.getProductGallery.reduce((unique, item) => unique.find(elem => elem.src === item.src) ? unique : [...unique, item], [])
    },
    isOnline (value) {
      return onlineHelper.isOnline
    },
    getProductOptions () {
      return this.getCurrentProduct.configurable_options
    },
    getOfflineImage () {
      return {
        src: this.getThumbnail(this.getCurrentProduct.image, config.products.thumbnails.width, config.products.thumbnails.height),
        error: this.getThumbnail(this.getCurrentProduct.image, config.products.thumbnails.width, config.products.thumbnails.height),
        loading: this.getThumbnail(this.getCurrentProduct.image, config.products.thumbnails.width, config.products.thumbnails.height)
      }
    },
    getAvailableFilters () {
      return getAvailableFiltersByProduct(this.getCurrentProduct)
    },
    getSelectedFilters () {
      return getSelectedFiltersByProduct(this.getCurrentProduct, this.getCurrentProductConfiguration)
    },
    isAllAttrSelected () {
      // length of selected custom options
      let customOptionLength = Object.keys(this.getCurrentCustomOptions).length
      let attributeLength = this.selectedAttr.length
      let summLength = customOptionLength + attributeLength

      // length of selected attributes
      let allCustomOpttionsLength = this.getCurrentProduct.custom_options ? this.getCurrentProduct.custom_options.length : 0
      let allAttributeLength = Object.keys(this.getAvailableFilters).length
      let allSummLength = this.getCurrentProduct.parent_simples_alternative_select
        ? allCustomOpttionsLength
        : allCustomOpttionsLength + allAttributeLength

      return allSummLength === summLength
    },
    selectedOptions () {
      let customLabels = this.getCurrentCustomOptions ? Object.keys(this.getCurrentCustomOptions).map(option => this.getLabelForCustomOptions(option)) : []
      return [...customLabels, ...this.selectedAttr]
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(this.getCurrentProduct.type_id)
    },
    isAddToCartDisabled () {
      if (this.quantityError || this.isStockInfoLoading) {
        return true
      }
      return false
    },
    thumbnailsWithColor () {
      var set = {}
      for (var z = this.getCurrentProduct.configurable_children.length; z--;) {
        var item = this.getCurrentProduct.configurable_children[z]
        set[item.color] = item.thumbnail
      }
      return set
    }
  },
  async mounted () {
    this.installWidget()
    await this.$store.dispatch('recently-viewed/addItem', this.getCurrentProduct)
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next((vm) => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
        let product = vm.$store.getters['product/getCurrentProduct']
        let childSku = from.query ? from.query.childSku : ''
        if (product.sku === childSku) {
          vm.getLink()
        }
        vm.getQuantity()
      })
    }
  },
  watch: {
    isOnline: {
      handler (isOnline) {
        if (isOnline) {
          this.getQuantity()
        }
      }
    }
  },
  async asyncData ({ store, route, context }) {
    if (context) context.output.cacheTags.add('product')
    const product = await store.dispatch('product/loadProduct', { parentSku: route.params.parentSku, childSku: route && route.params && route.params.childSku ? route.params.childSku : null })
    const loadBreadcrumbsPromise = store.dispatch('product/loadProductBreadcrumbs', { product })
    // await store.dispatch('review/list', { productId: product.parent_id })
    if (isServer) await loadBreadcrumbsPromise
    catalogHooksExecutors.productPageVisited(product)
  },
  beforeMount () {
    this.sortVariantsInStock()
    this.$bus.$on('has-scrolled', this.onHasScrolled)
    this.setBlockedSizeToTrue()
    this.$bus.$on('product-after-load', this.setBlockedSizeToTrue)
    this.$bus.$on('product-after-load', this.resetWidget)
    this.$bus.$on('product-after-load', this.sortVariantsInStock)
    this.$bus.$on('product-after-load', this.addRecentlyViewed)
  },
  beforeDestroy () {
    this.$bus.$off('has-scrolled', this.onHasScrolled)
    this.$bus.$off('product-after-load', this.setBlockedSizeToTrue)
    this.$bus.$off('product-after-load', this.resetWidget)
    this.$bus.$off('product-after-load', this.sortVariantsInStock)
    this.$bus.$off('product-after-load', this.addRecentlyViewed)
    this.unsetAttributesUnblocked()
  },
  methods: {
    stopWidgetInterwal () {
      clearInterval(this.timer)
      this.timer = null
    },
    setWidgetOptions () {
      if (!this.minPrice) return
      window.openpayu = {}
      window.openpayu.options = {
        creditAmount: this.minPrice.price_incl_tax,
        posId: '381987',
        key: 'e3',
        showLongDescription: true
      };
      window.OpenPayU.Installments.miniInstallment(`#installment-mini`);
      window.OpenPayU.Installments.miniInstallment(`#installment-mini-mobile`);
      window.OpenPayU.Installments.miniInstallment(`#installment-mini-mobile2`);
    },
    installWidget () {
      if (!this.minPrice || !this.minPrice.price_incl_tax || (this.minPrice.price_incl_tax < 300 && this.minPrice.price_incl_tax > 20000)) return
      this.timer = setInterval(() => {
        if (window.OpenPayU) {
          this.setWidgetOptions()
          this.stopWidgetInterwal()
        }
      }, 1000)
    },
    resetWidget () {
      document.getElementById('installment-mini').innerHTML = ''
      document.getElementById('installment-mini-mobile').innerHTML = ''
      document.getElementById('installment-mini-mobile2').innerHTML = ''
      this.installWidget()
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible && !this.seen) {
        this.seen = true
      }
    },
    // sanitizeHtml: sanitizeHtml,
    sortVariantsInStock (code) {
      if (code && typeof code === 'string') {
        let sortOrder = (this.sortedCodeOrder.code === code) && (this.sortedCodeOrder.order === 'asc') ? 'desc' : 'asc'
        this.sortedCodeOrder = { 'code': code, 'order': sortOrder }
        this.sortedEcommerceVariants = this.sortedEcommerceVariants.sort((a, b) => {
          if (Number(this.getLabelforValue(code, a[code])) < Number(this.getLabelforValue(code, b[code]))) {
            return this.sortedCodeOrder.order === 'asc' ? -1 : 1
          }
          if (Number(this.getLabelforValue(code, a[code])) > Number(this.getLabelforValue(code, b[code]))) {
            return this.sortedCodeOrder.order === 'asc' ? 1 : -1
          }
          return 0
        })
      } else {
        this.$store.dispatch('ui/unsetAttributesUnblocked')
        this.isEcommerceVariantsOpened = false
        this.sortedCodeOrder = {}
        let ecommerceValue = this.getLabelforValue('ecommerce', this.getCurrentProduct.ecommerce)
        if (ecommerceValue === 'yes') {
          let allProducts = this.getCurrentProduct.configurable_children
          if (allProducts && allProducts.length) {
            this.sortedEcommerceVariants = allProducts.filter(prod => prod.stock.is_in_stock)
          } else {
            this.sortedEcommerceVariants = []
          }
        } else if (this.getCurrentProduct.parent_simples_alternative_select) {
          this.sortedEcommerceVariants = this.getCurrentProduct.configurable_children
        } else {
          this.sortedEcommerceVariants = []
        }
      }
    },
    async selectVariant (variant) {
      this.$store.dispatch('ui/setAttributesUnblocked') // unblock product after choosing first option
      if (variant.sku === this.getCurrentProduct.sku) return
      let changedConfig = {}
      for (let option of this.getProductOptions) {
        let value = variant[option.attribute_code]
        if (value) {
          changedConfig[option.attribute_code] = {
            attribute_code: option.attribute_code,
            id: value
          }
        }
      }
      await this.$store.dispatch('product/configure', {
        product: this.getCurrentProduct,
        configuration: changedConfig,
        selectDefaultVariant: true,
        fallbackToDefaultWhenNoAvailable: false,
        setProductErorrs: true
      }, { root: true })
    },
    async addRecentlyViewed () {
      this.$store.dispatch('recently-viewed/addItem', this.getCurrentProduct)
    },
    getLabelForCustomOptions (option_id) {
      let option = this.getCurrentProduct.custom_options
        .find(option => option.option_id.toString() === option_id.toString())
      return option
        ? option.title
        : null
    },
    getValueLabelForCustomOption (option_id, value_id) {
      let option = this.getCurrentProduct.custom_options
        .find(option => option.option_id.toString() === option_id.toString())
      if (option) {
        let value = option.values.find(elem => elem.option_type_id.toString() === value_id.toString())
        return value ? value.title : null
      }
    },
    isMoreThan5Elem (optionCode) {
      return this.getAvailableFilters[optionCode] && this.getAvailableFilters[optionCode].length > 5
    },
    validateAddToCart () {
      this.isHideValidation = false
    },
    changeHideAttr (key) {
      this.isHideAttr.push(key)
    },
    updateProductQty (e) {
      this.getCurrentProduct.qty = Number(e)
    },
    onHasScrolled () {
      this.hasScrolled()
      this.bottom = this.bottomVisible()
    },
    scrollTo () {
      let elementToView = document.getElementById('scrollTo')
      elementToView.scrollIntoView({ behavior: 'smooth' })
    },
    getLabelsfor (type) {
      if (this.listByCode && this.listByCode[type]) {
        let allAttrObjects = this.listByCode[type].options.filter((option) => {
          if (Array.isArray(this.getCurrentProduct[type])) {
            for (let element of this.getCurrentProduct[type]) {
              if (option.value === String(element)) {
                return option.value === String(element)
              }
            }
          } else {
            return option.value === String(this.getCurrentProduct[type])
          }
        })
        if (allAttrObjects && allAttrObjects.length) {
          return allAttrObjects.map(el => {
            return el.label
          })
        } else {
          return false
        }
      }
    },
    unsetAttributesUnblocked () {
      this.$store.dispatch('ui/unsetAttributesUnblocked')
    },
    setBlockedSizeToTrue () {
      this.isHideValidation = true
      this.selectedAttr = this.getProductOptions.reduce((memo, options) => {
        if (options.values.length === 1) {
          memo.push(options.attribute_code)
        }
        return memo
      }, [])
      // this.selectedAttr = []
    },
    setBlockedSizeToFalse (type) {
      if (type && !this.selectedAttr.includes(type)) {
        this.selectedAttr.push(type)
      }
    },
    variantIsInStock (type, id) {
      if (id) {
        let allProducts = this.getCurrentProduct.configurable_children
        return allProducts
          .filter(prod => prod[type] === Number(id))
          .some(prod => prod.stock.is_in_stock)
      } else {
        return false
      }
    },
    sortById (filters) {
      return [...filters].sort((a, b) => {
        if (Number(a.label) < Number(b.label)) { return -1 }
        if (Number(a.label) > Number(b.label)) { return 1 }
        return 0
      })
    },
    changeFilter (variant) {
      this.$bus.$emit(
        'filter-changed-product',
        Object.assign({ attribute_code: variant.type }, variant)
      )
      this.getQuantity()
      this.setBlockedSizeToFalse(variant.type)
    },
    isOptionAvailable (option) { // check if the option is available
      const currentConfig = Object.assign({}, this.getCurrentProductConfiguration)
      currentConfig[option.type] = option
      return isOptionAvailableAsync(this.$store, { product: this.getCurrentProduct, configuration: currentConfig })
    },
    async getLink () {
      this.$store.dispatch('ui/setAttributesUnblocked')
    },
    async getQuantity () {
      if (this.isStockInfoLoading) return // stock info is already loading
      this.isStockInfoLoading = true
      try {
        const res = await this.$store.dispatch('stock/check', {
          product: this.getCurrentProduct,
          qty: this.getCurrentProduct.qty
        })
        this.manageQuantity = res.isManageStock
        this.maxQuantity = res.isManageStock ? res.qty : null
      } finally {
        this.isStockInfoLoading = false
      }
    },
    handleQuantityError (error) {
      this.quantityError = error
    },
    getLastCategoryId () {
      let productCategories = this.getCurrentProduct.category ? this.getCurrentProduct.category && this.getCurrentProduct.category.map(c => c.category_id) : ''
      return productCategories && productCategories.length ? productCategories.slice(-1)[0] : ''
    },
    changeModalText (label) {
      if (label) {
        this.selectedText = label
      }
    },
    isShowStickyImg () {
      if (window.innerHeight < 880) {
        return false
      } else {
        return true
      }
    },
    colorFrom (label) {
      if (label) {
        return 'background-color: #' + label
      }
    },
    calculateWidth () {
      if (this.$refs.stickyContainer) {
        this.calculatedWidth = this.$refs.stickyContainer.offsetWidth
        return `${this.calculatedWidth}`
      }
    },
    getThumbnail (i) {
      return _thumbnailHelper(i, 500, 500)
    },
    getSmallThumbnail (i) {
      return _thumbnailHelper(i, 120, 120)
    },
    bottomVisible () {
      const footer = document.getElementById('main-footer') ? document.getElementById('main-footer').scrollHeight : 0
      const footerFromTop = document.getElementById('main-footer') ? document.getElementById('main-footer').offsetTop : 0
      const element = document.getElementById('stickyBox') ? document.getElementById('stickyBox').scrollHeight : 0
      const scrollY = window.scrollY
      const pageHeight = document.documentElement.scrollHeight
      let heightFromTop1 = pageHeight - element - footer
      this.heightFromTop = `${heightFromTop1}`
      return scrollY > footerFromTop - element - 150
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (this.scrollTop > 250) {
        this.productHasScroll = true
      } else {
        this.productHasScroll = false
      }
      this.lastScrollTop = this.scrollTop
    }
  },
  validations: {
    product: {
      qty: {
        minValue: minValue(1)
      }
    }
  },
  metaInfo () {
    const storeView = currentStoreView();
    const href = this.$router.resolve(
      this.localizedRoute(
        {
          name: this.getCurrentProduct.type_id + '-product-amp',
          params: {
            parentSku: this.getCurrentProduct.parentSku
              ? this.getCurrentProduct.parentSku
              : this.getCurrentProduct.sku,
            slug: this.getCurrentProduct.slug,
            childSku: this.getCurrentProduct.sku
          }
        },
        storeView.storeCode
      )
    ).href;
    const meta = [
      {
        vmid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content:
          (this.getCurrentProduct.meta_title || this.getCurrentProduct.name) +
          ' - Sprawdź na artykulygrzewcze.pl'
      },
      {
        vmid: 'og:url',
        name: 'og:url',
        property: 'og:url',
        content:
          'https://artykulygrzewcze.pl/' +
          this.getCurrentProduct.url_path +
          '?childSku=' +
          this.getCurrentProduct.sku
      },
      { vmid: 'og:type', name: 'og:type', property: 'og:type', content: 'product' },
      {
        vmid: 'og:image',
        property: this.getProductGallery && this.getProductGallery.length && this.getProductGallery[0].src ? 'og:image' : '',
        content: this.getProductGallery && this.getProductGallery.length && this.getProductGallery[0].src ? htmlDecode(this.getProductGallery[0].src) : ''
      },
      {
        name: 'og:image:width',
        property: 'og:image:width',
        content: '600'
      },
      {
        name: 'og:image:height',
        property: 'og:image:height',
        content: '744'
      }
    ];
    if (
      this.getCurrentProduct.meta_description ||
      this.getCurrentProduct.description
    ) {
      let description = this.getCurrentProduct.meta_description || this.getCurrentProduct.description
      // description = description && this.sanitizeHtml(description, { allowedTags: [], allowedAttributes: {} })
      let lengthDescription = description && description.slice(0, 160)
      if (description) {
        let lastIndex = lengthDescription.lastIndexOf(' ')
        lengthDescription = lengthDescription.slice(0, lastIndex)
      }
      meta.push({
        vmid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: lengthDescription
      });
      meta.push({
        vmid: 'description',
        name: 'description',
        content: lengthDescription
      });
    }
    return {
      script: [
        {
          src: 'https://static.payu.com/res/v2/widget-products-installments.min.js'
        }
      ],
      link: [
        {
          href: 'https://static.payu.com/res/v2/layout/style.css',
          rel: 'stylesheet'
        },
        {
          rel: 'canonical',
          href: config.server.hostName + localizedRoute(
            '/' + this.getCurrentProduct.parent_url_path
          )
        },
        {
          rel: 'preload',
          as: 'image',
          href: this.getProductGallery[0] && this.getProductGallery[0].src
        }
      ],
      title: this.getCurrentProduct.meta_title || this.getCurrentProduct.name,
      meta
    };
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
$color-tertiary: color(tertiary);
$color-secondary: color(secondary);
$color-alternative: color(alternative);
$color-white: color(white);
$bg-secondary: color(secondary, $colors-background);
$primary-orange: color(primary-orange);

/deep/ .payu-widget-installments-mini {
  color: #191919 !important;
}
/deep/ .payu-widget-installments-mini-amount {
  font-weight: 700;
}

.connection-style {
  display: block;
  word-break: break-all;
}
.top-section-tablet {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    display: flex;
    width: 100%;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.hidde-tablet {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    display: none;
  }
}
.show-tablet {
  display: none;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    display: block;
  }
}
.selected-producted-tabs {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    display: flex;
    .container-selected-options {
      padding-left: 20px;
      .opt-t {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
.selected-title {
  min-width: fit-content;
}
.variants-tablet {
   @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    padding: 5px;
  }
}
.tablet-add-to-cart {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
   min-width: 100%;
  }
}
.col-tablet {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.media-padding-right {
  @media (max-width: 991px) {
    padding-right: 0;
  }
}
.stickyBox {
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.icon-zawory {
  color: white;
}
.stickyContainer-image {
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}
.shadow-hover-elemet {
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}
.icon-position-mobile {
  font-size: 21px;
  @media (max-width: 767px) {
    font-size: 24px;
    position: absolute;
    right: 0;
  }
}
.icon-position-mobile-zawory {
  @media (max-width: 767px) {
    font-size: 24px;
    position: absolute;
    right: 7px;
  }
}
.image-container {
  overflow: hidden;
  max-height: 350px;
  .promocja {
    top: 0;
  }
}

.available-variation-button {
  display: none;
  padding: 5px 15px;
  border: 1px solid $primary-orange;
  width: fit-content;
  border-radius: 15px;
  font-size: 0.8em;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px),(max-width: 767px) {
    display: block;
    min-width: 300px;
  }
}
.divider {
  height: 15px;
  margin: 0 -25px;
}
.bottom-fixed {
  position: absolute;
}
.fixedWithScroll {
  position: fixed;
  top: 150px !important;
  z-index: 1;
}
.product {
  &__add-to-compare {
    display: none;
    @media (min-width: 767px) {
      display: block;
    }
  }
}

.error {
  color: red;
  font-weight: bold;
  padding-bottom: 15px;
}
.data {
  @media (max-width: 767px) {
    border-bottom: 1px solid $bg-secondary;
  }
}

.product-name {
  @media (max-width: 767px) {
    font-size: 26px;
  }
}
.product-dostepny {
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.price {
  @media (max-width: 767px) {
    color: $color-primary;
  }
}

.variants-wrapper {
  @media (max-width: 767px) {
    padding-bottom: 5px;
  }

 .sizes {
    &.hidden-attrinute {
      max-height: 55px;
      overflow: hidden;
      position: relative;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      width: 100%;
    }
  }
  .show-button {
    color: #ff8100;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    top: 25px;
    right: 0;
  }
}

.product-top-section {
  padding: 0 20px;
  @media (max-width: 991px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 0;
    background-color: $color-white;
  }
}

.xspy15 {
  @media (max-width: 767px) {
    padding: 15px 0;
  }
}

.web-share {
  float: right;
}
.label-free {
  display: inline-flex;
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  span {
    border-left: 6px dotted white;
    border-right: 6px dotted white;
    white-space: nowrap;
    margin-left: -3px;
    margin-right: -3px;
    display: flex;
    align-items: center;
  }
}
.check-icon {
  position: absolute;
  left: -2px;
  font-weight: bolder;
  font-size: 30px;
}
.bottom {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.tile {
  top: 6px;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 120px;
  &__label {
    margin-top: 2px;
    font-size: 14px;
    padding: 3px 5px;
    @media (max-width: 767px) {
      font-size: 12px;
      padding: 2px 2px;
    }
  }
}
.ecommerce-products-footer {
  text-align: center;
  padding-bottom: 10px;
  /* padding-top: 5px; */
  cursor: pointer;
  color: #ff8100;
  &.opened {
    display: none;
  }
}
.ecommerce-products-header {
  margin: 10px 0 0;
  font-size: 12px;
  color: black;
  padding-right: 15px;
  text-align: center;
  justify-content: space-around;
  .variant.active {
    font-weight: 500;
    color: #ff8100;
  }
}
.ecommerce-products {
  &.opened {
    max-height: initial;
  }
  color: black;
  max-height: 320px;
  overflow-y: hidden;
  box-shadow: 0px 0px 14px #e2e2e2;
  margin: 10px 0px;
  padding: 8px;
  .variants-box {
    cursor: pointer;
    justify-content: space-around;
    height: 50px;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    &.active {
      box-shadow: 0px 0px 14px #e2e2e2;
      border: 2px solid #7DBA10;
    }
    &:hover {
      box-shadow: 0px 0px 14px #e2e2e2;
    }
    .variant {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 0;
      box-sizing: border-box;
      .variant-color {
        width: 22px;
        height: 22px;
      }
    }
  }
  /deep/ .variants-box:nth-child(1) {
    .variant {
      .variant-color {
        span {
          bottom: auto;
          z-index: 1;
          top: 30px;
        }
      }
    }
  }
  /deep/ .variants-box:nth-child(2) {
    .variant {
      .variant-color {
        span {
          bottom: auto;
          z-index: 1;
          top: 30px;
        }
      }
    }
  }
  /deep/ .variants-box:nth-child(3) {
    .variant {
      .variant-color {
        span {
          bottom: auto;
          z-index: 1;
          top: 30px;
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #d9d9d9;
  margin-top: 10px;
}
::-webkit-scrollbar-thumb {
  background: #7DBA10;
  border-radius: 10px;
  margin-right: 10px;
}
.fs8px {
  font-size: 8px;
}
.desktop-padding {
  padding: 25px 0;
  @media (max-width: 767px) {
    padding: 0;
  }
}
</style>
