<template>
  <div class="media-gallery-mobile">
    <transition name="fade">
      <img
        :src="getCurrentImage.src"
        width="530"
        height="530"
        :alt="productName"
      >
    </transition>
    <button
      v-show="!isFirstImage"
      class="nav-button prev"
      type="button"
      aria-label="Prev image"
      @click="showPrevImage()"
    >
      ❮
    </button>
    <button
      v-show="!isLastImage"
      class="nav-button next"
      type="button"
      aria-label="Next image"
      @click="showNextImage()"
    >
      ❯
    </button>
    <i
      class="zoom-in icon-search-alt p15 pointer cl-main-black"
      @click="openOverlay"
    />
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    productName: {
      type: String,
      default: ''
    },
    gallery: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      currentShowed: 0
    }
  },
  mounted () {
    this.$bus.$on('product-after-load', this.resetImage);
  },
  destroyed () {
    this.$bus.$off('product-after-load', this.resetImage);
  },
  computed: {
    getCurrentImage () {
      return this.gallery.length && this.gallery[this.currentShowed]
    },
    isLastImage () {
      return (this.gallery.length - 1) === this.currentShowed
    },
    isFirstImage () {
      return this.currentShowed === 0
    }
  },
  methods: {
    resetImage () {
      this.currentShowed = 0
    },
    showNextImage () {
      this.currentShowed++
    },
    showPrevImage () {
      this.currentShowed--
    },
    openOverlay () {
      this.$emit('toggle', this.currentShowed)
    }
  }
}
</script>

<style lang="scss" scoped>
.media-gallery-mobile {
  @media (min-width: 768px) {
    display: none;
  }
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-bottom: 100%;
  .nav-button {
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    background-color: initial;
    border: 0px;
    padding: 10px;
    color: #FFF;
    background-color: #3859A6;
  }
  img {
    object-fit: contain;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .zoom-in {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
